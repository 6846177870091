@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Manrope&family=Montserrat&display=swap");

* {
  font-family: "Manrope", sans-serif;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow: clip;
}

.yarl__container {
  font-size: xx-large;
  background: none;
}
.yarl__slide_description_container {
  background-color: transparent !important;
}

.yarl__slide_captions_container {
  background-color: transparent !important;
}
